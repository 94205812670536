@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@400;500;600&display=swap');
@tailwind base;

@tailwind components;

@tailwind utilities;

.home {
    width: 100%;
    align-items: center;
    font-family: "Arial", sans-serif;
    color: #3e497a;
  }
  
  .about {
    width: 100%;
    height: calc(100vh - 100px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color:white;
    font-family: 'Roboto Mono', monospace;
    background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.60)), url("../assets/stanford-wide.jpg") no-repeat;
    background-size: cover;
  }


  .about #headshotImg {
    border-radius: 50%;
    /* width: 1.5rem; */
    width: 250px;
    height: auto;
    margin-top: 20px;
    margin-bottom: 0px;
  }
  
  .about .prompt {
    width: 60%;
    font-size: 40px;
  }
  .prompt svg {
    font-size: 60px;
    margin: 5px;
    color: white;
  }
  
  .skills {
    font-size: 35px;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  
  .skills h1 {
    color: #3e497a;
  }
  
  .skills .list {
    list-style: none;
    width: 60%;
    /* padding-inline-start: 0px; */
  }
  
  
  .list span {
    font-size: 20px;
  }
  
  @media only screen and (max-width: 600px) {
  
    .skills {
      text-align: center;
    }
    .list {
      padding: 0;
    }
  
    .skills h2 {
      font-size: 30px;
    }
  }