@import url('https://fonts.googleapis.com/css2?family=Jost&display=swap');

.navbar {
    width: 100%;
    height: 75px;
    background: #275ee7;
    top: 0;
    position: sticky;
    z-index: 9999; 
  }

  .parentContainer {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .links {
    width: 90%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: 'Jost', sans-serif;
    color: white;
  }

  .links .leftOptions {
    /* align-items: center; */
    height: 75px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

 .links #headshotNav {
    position: relative;
    /* height: 10%; */
    height: 50px;
    width: auto;
    max-height: 100%; /* Optional: Set the maximum height of the image */
    max-width: 100%;
    border-radius: 20%;
  }

  .rightOptions {
    float:right;
  }
  
  .navbar a {
    color: white;
    text-decoration: none;
    margin: 20px;
    font-size: 25px;
  }

  .nameLeft {
    font-family: 'Jost', sans-serif;
    font-weight: 600;
    font-size: 2.0rem;
  }
  
  .toggleButton {
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  
  .toggleButton svg {
    font-size: 50px;
  }
  .toggleButton button {
    margin-right: 20px;
    background-color: transparent;
    border: none;
    color: white;
    cursor: pointer;
  }
  
  #open {
    height: 100vh;
  }
  
  #open .links {
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 100px;
  }
  
  #open a {
    width: 100%;
    text-align: center;
  }
  
  @media only screen and (max-width: 900px) {
    .navbar a {
      width: 70px;
    }
  }
  
  @media only screen and (max-width: 600px) {
    .toggleButton {
      display: flex;
    }
    #close a, h1 {
      display: none;
    }
  }
  
  @media only screen and (min-width: 600px) {
    .toggleButton {
      display: none;
    }
  
    .hiddenLinks {
      display: none;
    }
  
    #open {
      height: 100px;
    }
  
    #open .links {
      flex-direction: row;
    }
  }
  