@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@400&display=swap');

.vertical-timeline-element-subtitle {
    font-weight: 400;
    padding-top: 5px;
    color: #e03e25;
    font-family: 'Rubik', sans-serif;
}

.timelineImg {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.image-container {
    display: flex;
    align-items: center;
    align-content: center;
    padding: 10px;
}
  
.image {
    width: 50%;
    height: auto;
}
  