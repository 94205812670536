@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@900&display=swap');


.projectTitles {
  /* background-color: red; */
  /* margin-top: 0px; */
  font-size: 25px;
  font-family: 'Roboto', sans-serif;
  font-weight: 900;
}

/* .viewAppsWrapper {
  display: inline-block;
  border-radius: 10px;
  overflow: hidden;
  background: transparent;
  margin-top: 100px;
}

.viewAppsWrapper .viewApps {
  display: flex;
  align-items: center;
  padding: 10px;
  border: 10px solid transparent;
  border-image: linear-gradient(to right, #007bff, #00aeff) 3;
}

.viewApps .appStoreImg {
  margin-right: 10px;
  width: 80px;
  height: 80px;
}

.viewApps .viewLbl {
  margin-left: 10px;
  background-image: linear-gradient(to right, #007bff, #0f96e9);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.viewAppsWrapper:hover {
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.5);
  transition: 0.3s ease-in;
  cursor: pointer;
} */

  
  @media only screen and (max-width: 1300px) {
    .projectList {
      grid-template-columns: 1fr 1fr;
    }
  }
  
  @media only screen and (max-width: 800px) {
    .projectList {
      grid-template-columns: 1fr;
    }
  }
  
  @media only screen and (max-width: 800px) {
    .projectList {
      width: 100%;
    }
    .projectItem {
      width: 300px;
      height: 300px;
    }
  }